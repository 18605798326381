import upcare_logo from '~/front/images/upcare_log.webp';

export function UsersLayout({
  children,
  title,
  leading,
}: {
  children: React.ReactNode;
  title: string | React.ReactNode;
  leading?: string | React.ReactNode;
}) {
  return (
    <main id="detail" className="w-full h-screen">
      <div className="grid md:grid-cols-[minmax(440px,50%)_1fr] h-screen">
        <div className="md:grid md:justify-center md:items-center overflow-auto px-6 md:py-12 pb-12">
          <div className="relative m-auto md:w-[390px] w-full pt-8">
            <div className="fixed top-0 left-0 right-0 p-2 border-b bg-white -mx-3 md:relative md:border-none md:mx-0">
              <img
                className="w-[143px] md:w-[390px]"
                src={upcare_logo}
                alt="UpCare"
              />
            </div>
            <div className="mt-16 text-base text-center font-semibold md:mt-8 md:text-xl md:text-left">
              {title}
            </div>
            {leading && <p className="mt-6 text-xs">{leading}</p>}
            <div className="w-full">{children}</div>
          </div>
        </div>
        <div className="bg-primary hidden md:grid"></div>
      </div>
    </main>
  );
}
